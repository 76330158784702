.ticket {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    background: #f7f7f7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ticket-header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.ticket-title {
    font-size: 24px;
    font-weight: bold;
}

.ticket-body p {
    margin: 5px 0;
}

.ticket-footer {
    text-align: center;
    margin-top: 20px;
}

/* Print Styles */
@media print {
    body * {
        visibility: hidden;
    }
    #ticket, #ticket * {
        visibility: visible;
    }
    #ticket {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 0;
    }
    .ticket-footer {
        display: none;
    }
}
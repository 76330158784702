.each-blog{
    width: 800px;
    background-color: white;
    max-height: 80vh;
    min-height: 40vh;
    overflow-y: auto;
    scrollbar-width: none;
}
.each-blog-image{
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.each-blog-description{
    width: 90%;
    color: #799a63;
    padding: 10px 30px;
    word-break: break-all;
    overflow-wrap: break-word;
}
.each-blog-title{
    font-family: "Krona One", sans-serif;
    font-size: 21px;
    padding: 0px 0px 10px;
}
.title-underline{
    border: 2px solid #799a63;
    width: 100px;
    border-radius: 10px;
    margin: 0px 0px 10px;
}

@media(max-width: 830px){
    .each-blog{
        width: 650px;
    }
}
@media(max-width: 680px){
    .each-blog{
        width: 450px;
    }
}
@media(max-width: 500px){
    .each-blog{
        width: 380px;
    }
}
.whole-payment-container{
   
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin-top: 60px;
    width: 100%;
}
.ticket-detail{
    border: 2px solid #12372a;
    border-radius: 20px;
    width: 400px;
    word-break: break-all;
    overflow-wrap: break-word;
    color: #789461;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    maxHeight: 90vh;
    overflow-y: auto;
    scrollbar-width: none;
}
.ticket-title{
    font-family: "Krona One", sans-serif;
    font-size: 21px;
    padding: 10px 0px 5px;
    line-height: 30px;
}
.ticket-description{
    line-height: 1.5;
    font-size: 16px;
}
@media(max-width:600px){
    .ticket-title{
        font-size: 16px;
        line-height: 20px;
    }
    .ticket-description{
        font-size: 14px;
    }
}
.container-form{
    width: 400px;
}
@media(max-width: 1000px){
    .whole-payment-container{
         gap: 30px;
         padding: 0 20px 0 20px;
      }
}
@media(max-width: 830px){
    .whole-payment-container{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    .ticket-detail{
        grid-row: 1 / 2;
        grid-column: 1 / 3;
        /* margin-left: 13%; */
    }
}
@media(max-width: 460px){
    .ticket-detail{
        width: 90%;
    }
    .container-form{
        width: 90%;
    }
    .ticket-detail{
        justify-content: center;
        margin: 0 auto;
    }
    .container {
        margin: 0 auto;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Krona+One&family=Krub:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
:root {
    --clr-dark: #12372a;
    --clr-nav: #789461;
    --clr-font: #f1f8ff;
  }
.blogs-container{
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    position: relative;
}
.each-blog-container{
    width: 350px;
    height: 415px;
    overflow-y: auto;
    scrollbar-width: none;
    text-decoration: none;
    margin: 20px;
    color: var(--clr-nav);
    font-family: "Krub", sans-serif;
    box-shadow: 0 10px 10px 5px rgba(100, 100, 100, .2);
}
.blog-image{
    width: 100%;
    height: 280px;
    object-fit: cover;
}
.each-blog-container-title{
    font-family: "Krona One", sans-serif;
    font-size: 21px;
    padding: 10px 0px 5px;
}
.title-underline{
    border: 2px solid var(--clr-dark);
    width: 100px;
    border-radius: 10px;
}
.each-blog-container-description{
    padding: 10px 20px 5px;
    height: 100px;
    word-break: break-all;
    overflow-wrap: break-word;
}

.blog-modal{
    width: 800px;
    display: flex;
    align-items: center;
    margin: 0px auto;
}


@media(max-width: 1170px){
    .blogs-container{
        grid-template-columns: auto auto;
    }
}
@media(max-width: 777px){
    .each-blog-container{
        width: 300px;
    }
}
@media(max-width: 830px){
    .blog-modal{
        width: 650px;
    }
}
@media(max-width: 680px){
    .blogs-container{
        grid-template-columns: auto;
    }
    .each-blog-container{
        width: 350px;
    }
    .blog-modal{
        width: 450px;
    }
}
@media(max-width: 620px){
    .blog-modal{
        width: 500px;
    }
}
@media(max-width: 520px){
    .blogs-container{
        grid-template-columns: auto;
    }
    .each-blog-container{
        width: 300px;
        height: 350px;
    }
    .blog-image{
        height: 180px;
    }
    .each-blog-container-description{
        height: 130px;
    }
}
@media(max-width: 500px){
    .blog-modal{
        width: 380px;
    }
}
.footer {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  background-color: var(--clr-font);
  padding-top: 4rem;
}

.newsletter_form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .3rem;
  flex-direction: column;
  /* padding: .1rem; */
}

.login-button {
  width: 300px;
  background-color: #13A014;
  padding: 10px 5px;
  color: white;
  font-family: "Kumbh Sans", sans-serif;
  margin-top: 15px;
  font-size: 20px;
  border: 1px solid #fff;
  border-radius: 5px;
}

.login-button:hover,
.login-button:focus,
.login-button:active,
.login-button:checked {
  background-color: #51c951;
  border: none;
}

.bottom {
  background-color: var(--clr-font);
  margin-top: 5rem;
}

.footer .first {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

.footer .first img {
  max-width: 15rem;
  margin: 0rem auto;
}

.join_par {
  padding: 1rem 5rem;
  text-align: center;
  line-height: 1.4;
  font-size: 20px;
  color: #13A014;
  font-weight: 500;
}

.footer .first p:last-child {
  padding: 1rem 5rem;
}

.subscribe {
  margin: 0rem 5rem;
}

.subscribe input {
  background-color: transparent;
  padding: 1rem 5rem;
  border: 2px solid var(--clr-dark);
}

.subscribe button {
  background: var(--clr-dark);
  padding: 12px 24px;
  border-radius: 4px;
  border: 1px solid var(--clr-dark);
  cursor: pointer;
}

.subscribe button a {
  color: var(--clr-font);
  font-size: 1.4em;
  text-decoration: none;
  font-family: "Work Sans", sans-serif;
  color: #f1f8ff;
}

.second {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.one h6,
.two h6,
.three h6 {
  padding-bottom: 1rem;
  font-size: 1.2em;
  color: var(--clr-dark);
}

.one a,
.two a,
.three a {
  padding: 0.8rem 0.8rem;
  display: block;
  text-decoration: none;
  color: black;
  transition: .3s ease;
}

.one a:hover,
.two a:hover,
.three a:hover {
  color: #13A014;
}

.footer__line {
  color: var(--clr-dark);
  width: 90%;
  margin: 2rem auto;
}

.bottom__two {
  text-align: center;
  padding-bottom: 1rem;
}

.right p {
  padding: 0 6rem;
  text-align: center;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding-right: 6rem;
  width: 100%;
}

@media (max-width: 1020px) {
  .footer {
    grid-template-columns: repeat(1, 1fr);
  }

  .footer .first {
    text-align: center;
  }

  .footer .second {
    text-align: center;
    padding-top: 2rem;
  }

  .footer .three {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #twitter_icon {
    margin-right: 2rem !important;
  }
}

@media (max-width: 690px) {
  .right p {
    padding: 0 4rem;
  }

  .right {
    display: flex;
    margin-left: auto;
    padding-right: 2rem;
  }
}

@media (max-width: 5070px) {
  .subscribe button {
    margin-top: 1rem;
  }
}

@media (max-width: 400px) {
  .second {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }

  .one h6,
  .two h6,
  .three h6 {
    padding: 3rem 0 1rem;
    font-size: 1.2em;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Freeman&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

/* :root {
  --clr-dark: #12372a;
  --clr-nav: #789461;
  --clr-font: #f1f8ff;
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #f1f8ff;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #13A014;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 9999;
  box-shadow: 1px 1px 2px var(--textColor);
}

.logo {
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .1rem;
  z-index: 300;
  cursor: pointer;
}

.logo img {
  width: 100%;
  height: 50px;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
}

nav a {
  color: var(--textColor);
  text-decoration: none;
  text-wrap: nowrap;
  transition: all .5s ease;
  font-weight: bold;
}

.nav_bar a,
.responsive_nav a {
  position: relative;
}

.nav_bar a::after,
.responsive_nav a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 3px;
  background-color: var(--textColor);
  border-radius: 40px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .3s;
}

.nav_bar a:hover::after,
  .responsive_nav a:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}

.active {
  border-bottom: 3px solid #13A014;
  transition: all .5s ease;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
  transition: all .5s ease-in-out;
}

.nav-btn {
  display: none;
  font-size: 60px;
}

@media only screen and (max-width: 990px) {
  nav {
    gap: 4rem;
  }
}

@media only screen and (max-width: 900px) {
  .responsive_nav a {
    font-size: 15px;
  }

  nav {
    gap: 3rem;
  }
}

@media only screen and (max-width: 800px) {
  .nav-btn {
    display: block;
    color: #fff;
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: all 1s ease;
    z-index: 100;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 270px;
    margin-top: 60px;
  }

  nav .nav-close-btn,
  .nav_menubar {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 200;
  }

  nav a {
    font-size: 20px;
  }
}

.nav-close-btn,
.nav_menubar {
  color: #13A014;
}
@import url("https://fonts.googleapis.com/css2?family=Freeman&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Digital-7&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Lobster&display=swap');

:root {
  --main-color: #13A014;
  --clr-light: #789461;
  --clr-font: #f1f8ff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  line-height: 1;
  font-family: "Work Sans", sans-serif;
  background-color: var(--clr-font);
}

.home {
  position: relative;
  height: max-content;
  background-color: #f1f8ff;
  font-family: "Work Sans", sans-serif;
  /* background-image: url('https://plus.unsplash.com/premium_vector-1711987875533-982273b8ad4d?bg=FFFFFF&q=80&w=1800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'); */
  /* background-image: url('https://plus.unsplash.com/premium_vector-1711987664181-319d6f068855?bg=FFFFFF&q=80&w=1800&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'); */
  background-image: url('../assets/images/home.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 70vh;
  /* padding-top: 4rem; */
}

/* .home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
} */

.top_home {
  /*display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  gap: 1rem;*/
  padding:1rem;
}

.home__logo {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
}

.home__logo h2 {
  color: #28ac28;
  z-index: 1;
  margin-bottom: 2rem;
  font-size: 90px;
  font-family: "Lobster", sans-serif;
  font-weight: 700;
  font-style: italic;
  /* font-weight: 600; */
}

.home__logo h2 span {
  color: #fff;
  font-family: "Arvo", serif;
  font-weight: 700;
  font-style: italic;
}

.counter_poster {
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter_title h2 {
  color: #23bb23;
  z-index: 1;
  margin-bottom: 1rem;
  font-weight: 600;
  width: 100%;
}

.counter_title img {
  width: 50%;
  margin-bottom: 1rem;
  border-radius: 10px;
  cursor: pointer;
}

.counter_poster img {
  box-shadow: 1px 4px 8px rgb(125, 246, 4); /* Box shadow for the image */
  border-radius: 8px; /* Optional: adds rounded corners */
}

.home__logo p {
  position: relative;
  width: 40%;
  padding: 1.8rem 1.5rem;
  background-color: #f1f8ffd2;
  color: #382d2d9f;
  /* margin-left: 2rem; */
  border-radius: 1rem;
  font-size: 20px;
  font-family: 'Montserrat';
  z-index: 1;
  text-align: justify;
  display: flex;
  justify-self: start;
}

.home__logo p::before {
  content: '\201C';
  font-size: 60px;
  font-weight: bold;
  color: #13A014;
  position: absolute;
  top: 0;
  left: 10px;
}

.home__logo p::after {
  content: '\201D';
  font-size: 60px;
  font-weight: bold;
  color: #13A014;
  position: absolute;
  bottom: -10%;
  right: 10px;
}

.home__logo img {
  max-width: 40rem;
  margin: 5px auto;
  position: absolute;
  top: 5rem;
  left: 2rem;
}

.btn {
  display: flex;
  justify-content: center;
  margin: 3rem auto;
  width: 100%;
  gap: 2rem;
}

.top_home .countdown {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 1;
}

.counter_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  /* gap: 1rem; */
}

.time {
  
  z-index: 1;
  text-align: center;
  color: white;

}

.time span {
  font-size: 3em;
  /* color: #13A014; */
  font-weight: bold;
  display: inline-block;
  padding: 20px;
  font-family: "Digital-7", monospace;
  /* border: 1px solid #13A014; */
  border-radius: 10px;
}
.time p{
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
/* .time p {
  color: #13A014;
} */

.home__para {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  width: 70%;
  margin: 0 auto;
}

.home__para p {
  text-align: center;
  font-size: 20px;
  color: #f1f8ff;
  font-family: 'Montserrat';
  z-index: 1;
  text-wrap: wrap;
  font-weight: 500;

}

/* create */
.create {
  background-color: var(--clr-font);
  text-align: center;
}

.create h2 {
  margin: 0rem 20rem;
  padding: 3rem 0 1rem;
  font-size: 2em;
  text-align: center;
  color: var(--main-color);
}

.create p {
  margin: 0rem 16rem;
  padding-bottom: 3rem;
  font-size: 1.2em;
  line-height: 1.5;
}

.create .create__line {
  color: var(--main-color);
  width: 70%;
  margin: 0 auto;
}

/* upcoming events */
.event__container {
  display: flex;
  flex-direction: column;

}

.events {
  font-family: "Work Sans", sans-serif;
  background-color: var(--clr-font);
}

.events h2 {
  text-align: center;
  padding: 3rem 0 1rem;
  font-size: 2em;
  color: var(--main-color);
}

.event__para {
  text-align: center;
}

hr {
  color: var(--main-color);
  width: 46%;
  margin: 2rem auto;
}

.upcoming {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  flex-wrap: wrap;
  overflow: hidden;
}

.showall__btn {
  background: var(--main-color);
  display: flex;
  margin: 4rem auto 0;
  padding: 14px 85px;
  border-radius: 10px;
  border: 2px solid var(--main-color);
  cursor: pointer;
}

.showall__btn a {
  font-size: 1.4em;
  text-decoration: none;
  font-family: "Work Sans", sans-serif;
  color: #f1f8ff;
}

/* card */

.box img {
  border-radius: 15px 15px 0 0;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100vw;
  padding: 0 2rem;
  overflow: hidden;
}

.poster {
  display: flex;
  border: 2px solid var(--main-color);
  border-radius: 17px;
  gap: 10px;
  width: 300px;
  line-break: anywhere;
  height: 350px;
  padding: 0;
  overflow: hidden;
}

.poster img {
  object-fit: cover;
  width: 300px;
  height: 346px;
  border-radius: 15px;
}

.content {
  padding: 13px 14px 16px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1rem;
  border: 2px solid var(--main-color);
  border-radius: 10px;
  width: 300px;
  overflow: hidden;
}

.ticket_location,
.ticket_time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lock_icon:hover {
  color: #13A014;

}

.event__btn {
  background: var(--main-color);
  padding: 12px 20px;
  border-radius: 7px;
  border: 1px solid var(--main-color);
  display: flex;
  margin: 1rem auto;
}

.event__btn:hover a {
  color: #f1f8ff;
}

.event__btn a {
  font-size: 1.4em;
  color: var(--clr-font);
  text-decoration: none;
}


/* FAQ */

.faq__title {
  font-size: 2em;
  text-align: center;
  padding: 5rem 0 1rem;
  color: var(--main-color);
}

.faq__para {
  text-align: center;
  padding: 2rem 0;
  font-size: 1.2em;
}

.accordion {
  width: 700px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.item {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 20px 24px;
  padding-right: 48px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 24px;
  row-gap: 32px;
  align-items: center;
  transition: all .5s ease;
}

.accordion_item {
  padding: 1rem;
  transition: all .3s ease;
}


.accordion_summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.number {
  font-size: 1.5em;
  font-weight: 500;
}

.accordion_number {
  margin-right: 2rem;
}

.title2,
.icon2 {
  font-size: 1.4em;
  font-weight: 500;
  color: var(--main-color);
}

.content-box {
  grid-column: 2 / -1;
  padding-bottom: 16px;
  line-height: 1.6;
}

.content-box ul {
  margin-left: 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.open {
  border-top: 5px solid var(--main-color);
}

.accordion_number,
.title {
  color: var(--main-color);
  font-weight: 500;
  font-size: 1.2rem;
}

.faq__title__two {
  text-align: center;
  font-size: 2em;
  color: var(--main-color);
  padding: 3rem 0 1rem;
}

.faq__par__two {
  text-align: center;
  color: var(--main-color);
}

.faq button {
  margin: 2rem auto;
  display: flex;
}

.phone,
.location {
  text-align: center;
  padding: 2rem 0 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.phone span,
.location span {
  font-size: 1.4em;
  color: var(--main-color);
  padding-right: 1rem;
}

.contact__btn {
  background: var(--main-color);
  display: flex;
  margin: 4rem auto 0;
  padding: 10px 85px;
  border-radius: 10px;
  border: 2px solid var(--main-color);
  cursor: pointer;
}

.contact__btn a {
  font-size: 1.4em;
  text-decoration: none;
  font-family: "Work Sans", sans-serif;
  color: #f1f8ff;
}

.contact__btn:hover {
  background-color: #51c951;
  border: none;
}

.register {
  background: var(--clr-font);
  padding: 15px 25px;
  border-radius: 10px;
  border: 1px solid var(--main-color);
  cursor: pointer;
  font-size: 1.3em;
  color: var(--main-color);
  font-family: "Work Sans", sans-serif;
  transition: all .8s ease;
  z-index: 1;
  margin-bottom: 2rem;
}


.register:hover {
  background: var(--main-color);
  color: var(--clr-font);
}

.read_more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 1rem auto;
  padding: .6rem .7rem;
  border-radius: 4px;
  color: #13A014;
  border: 1px solid #13A014;
  text-decoration: none;
}

.read_more:hover {
  background-color: #13A014;
  color: #fff;
  transition: .5s;
  border: none;
}
.eventLasting{
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color:  #334233;
}

@media (max-width: 1140px) {
 

  .home__logo p {
    width: 60%;
    padding: 2.5rem 2rem;
  }

  .time {
    /* display: grid;
    padding: 1rem;
    text-align: center; */
  }
}

@media (max-width: 1100px) {
  .home__logo img {
    width: 33.5rem;
  }
}

@media (max-width: 950px) {
  .home__logo img {
    width: 32rem;
  }
}

@media (max-width: 870px) {
  .create h2 {
    margin: 0rem 14rem;
    padding: 3rem 0 1rem;
    font-size: 2em;
  }

  .create p {
    margin: 0rem 10rem;
    padding-bottom: 3rem;
    font-size: 1.1em;
  }

  
}

@media (max-width: 860px) {
  .accordion {
    width: 75%;
  }

  .title2,
  .icon2 {
    font-size: 1.2em;
  }

  .home__logo img {
    width: 30rem;
  }
}

@media(max-width:800px){
  .register {
    font-size: 1em;
    padding: 10px 20px;
  }
    .accordion_item {
      padding: 0.3rem;
      
    }
    .title{
      font-size: 17px;
    }
  
}


@media (max-width: 730px) {
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid #13A014;
    border-radius: 1rem;
    padding: 0;
    max-width: 400px;
  }

  .poster,
  .poster img,
  .content {
    border: none;
    width: 100%;
    border-radius: 0;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding: 1rem;
  }

  .home__logo p {
    font-size: 18px;
    width: 70%;
    margin: 10px auto;
  }

  .top_home {
    flex-direction: column;
  }



  .time span{
  font-size: 25px;
  }

  .home__para {
    margin-top: 1rem;
  }
}

@media (max-width: 660px) {
  .create h2 {
    margin: 0rem 10rem;
    padding: 3rem 0 1rem;
    font-size: 2em;
  }

  .create p {
    margin: 0rem 8rem;
    padding-bottom: 3rem;
    font-size: 1em;
  }
  .counter_title img {
    object-fit: contain;
    background-position: center;
    margin-bottom: 1rem;
  }
  .box {
    width: 90%;
  }

  
}


@media (max-width: 510px) {
  .create h2 {
    margin: 0rem 4rem;
    padding: 3rem 0 1rem;
    font-size: 2em;
  }

  .create p {
    margin: 0rem 2rem;
    padding-bottom: 3rem;
    font-size: 1em;
  }
  .title{
    font-size: 15px;
  }


}

@media (max-width: 430px) {
  .upcoming {
    margin: 1rem;
  }
  .countdown {
   display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 100px;
    /* margin-right: 3rem; */
    column-gap: 2rem;
    row-gap: 2rem;
  }
  

}

:root {
  --main-color: #13A014;
  --clr-light: #2b5b2b;
  --clr-font: #f1f8ff;
}

.about__page {
  /* padding-top: 8rem; */
  /* width: 100%; */
  overflow: hidden;

}

.about__title {
  position: relative;
  padding: 8rem 0 12rem;
  text-align: center;
  font-size: 2em;
  height: 100vh;
  background-image: url('../assets/images/about.jpg');
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  object-fit: cover;
  background-position: top;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .about__title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
} */

.title_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about__title .title_container h2 {
  z-index: 1;
  font-weight: bold;
  color: var(--clr-font);
}

.about__title h2 span {
  color: var(--main-color);
  background-color: var(--clr-font);
  border-radius: 10px;
  padding: 0 5px;
}

.offer__title {
  text-align: center;
  font-size: 2em;
  color: var(--main-color);
  padding: 3rem 0 1rem;
  padding-bottom: 3rem;
}

.offer__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  width: 100%;
  overflow: hidden;
  padding-left: 1rem;
}

.offer__content .offer__box {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 3rem;
  margin: 0;
}

.offer__icon {
  font-size: 2rem;
  color: var(--main-color);
  padding-right: 2rem;
  padding-top: 2rem;
}

.offer__text {
  text-align: left;
}

.offer__text h2 {
  font-size: 1.5rem;
}

.offer__text p {
  font-size: 1rem;
  padding-top: 0.5rem;
  width: 20rem;
}

.card__container {
  margin: 2rem auto;
  background-color: #f1f8ff;
}

.card__container h2 {
  text-align: center;
}

.cards {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  /* background-color: black; */
}

.card_box {
  width: 35%;
  min-height: 320px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #fff;
  margin: 2rem 0;

}

.card_box h3 {
  color: #13A014;
  font-size: 30px;
}

.card_box .card_title {
  color: #13A014;
  font-size: 30px;
  margin-top: .5rem;
}

.card_box .card_par p {
  font-size: 20px;
  color: #1c351c;
  line-height: 1.7;
}

.statistics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 5rem 4rem;
  text-align: center;
  /* background: var(--clr-light); */
  background-image: url('../assets/images/statistics.jpg');
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  object-fit: cover;
  background-position: top;
  background-attachment: fixed;
}

.statistics__box p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .3rem;
  font-size: 3em;
  color: var(--clr-font);
  font-weight: 500;
}

.statistics__box h2 {
  font-size: 1.2em;
  color: var(--clr-font);
}

.testimonial h2 {
  font-size: 2em;
  color: var(--main-color);
  text-align: center;
  padding: 4rem 0;
}

.testimonial__content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow__left svg {
  position: relative;
  left: 8rem;
}

.arrow__right svg {
  position: relative;
  right: 8rem;
}

.testimonial__text p {
  font-size: 1.3em;
  margin: 0 24rem;
  padding-bottom: 4rem;
}

.testimonial__text h3 {
  padding: 1rem 0;
  font-size: 1.5em;
  color: var(--main-color);
}

.testimonial__image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.arrow__button {
  font-size: 2.5em;
  background: transparent;
  border: 0;
  color: var(--main-color);
}

.event__package {
  background: var(--clr-light);
}

.event__package h2 {
  text-align: center;
  font-size: 2em;
  color: var(--clr-font);
  padding: 4rem 0;
}

.event__page {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 3rem;
  text-align: center;
  gap: 0 3rem;
}

.event__box {
  background: white;
  border-radius: 10px;
  margin-bottom: 4rem;
}

.event__box h3 {
  font-size: 1.5em;
  color: var(--main-color);
  padding: 2rem 0;
  text-decoration: underline;
  text-decoration-color: var(--main-color);
  text-decoration-thickness: 2px;
}

.event__price {
  font-size: 1.8em;
  padding-bottom: 1rem;
}

.event__btn {
  background: transparent;
  color: var(--main-color);
  font-size: 1.2em;
  margin: 3rem auto;
}

.event__btn:hover {
  background: var(--main-color);
  color: var(--clr-font);
}

.team h2 {
  text-align: center;
  font-size: 2em;
  color: var(--main-color);
  padding: 4rem 0 1rem;
}

.team p {
  text-align: center;
  font-size: 1.3em;
}

.team__page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
}

.team__member {
  padding-bottom: 4rem;
}

.team__member img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: contain;
}

.team__member h3 {
  font-size: 2em;
  color: var(--main-color);
}

.join {
  /* background: var(--clr-light); */
  background-image: url('../assets/images/twoGirls.jpg');
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  object-fit: cover;
  background-position: top;
  background-attachment: fixed;
}

.join h2 {
  text-align: center;
  font-size: 2em;
  color: var(--clr-font);
  padding: 4rem 0 1rem;
}

.join h3 {
  text-align: center;
  font-size: 4em;
  color: var(--main-color);
  font-weight: bold;
}

.join p {
  text-align: center;
  color: var(--clr-font);
  font-size: 1.3em;
  padding: 1rem;
}

.join__btn {
  background: var(--main-color);
  padding: 12px 24px;
  border-radius: 10px;
  border: 2px solid var(--main-color);
  font-size: 1.2em;
  margin: 2rem auto;
  color: var(--clr-font);
  justify-content: center;
  display: flex;
}

.join__two {
  padding-bottom: 4rem;
}

@media (max-width: 1100px) {
  .offer__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* margin: 0px 3rem 0 0; */
    gap: 3rem 0;
  }

  .offer__icon {
    padding-left: 3rem;
  }

  .testimonial__text p {
    margin: 0 22rem;
  }
}

@media (max-width: 980px) {
  .offer__icon {
    padding-left: 2rem;
  }

  .testimonial__text p {
    margin: 0 16rem;
  }

  .cards {
    gap: 4rem;
  }
}

@media (max-width: 880px) {
  .offer__icon {
    padding-left: 1rem;
  }

  .team__page {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
  }

  .testimonial__text p {
    margin: 0 12rem;
  }

  .event__page {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 820px) {
  .offer__icon {
    padding-left: .4rem;
  }

  .arrow__left svg {
    position: relative;
    left: 6rem;
  }

  .arrow__right svg {
    position: relative;
    right: 6rem;
  }
}

@media (max-width: 750px) {
  .offer__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem 0;
  }

  .cards {
    flex-direction: column;
  }

  .card_box {
    width: 70%;
  }

  .offer__text p {
    font-size: 1.2em;
    padding-top: 0.5rem;
    width: 33rem;
  }

  .offer__box {
    display: flex;
    padding-bottom: 1rem;
    justify-content: center;
  }

  .testimonial__text p {
    margin: 0 10rem;
  }

  .arrow__left svg {
    position: relative;
    left: 4rem;
  }

  .arrow__right svg {
    position: relative;
    right: 4rem;
  }
}

@media (max-width: 710px) {
  .offer__text p {
    font-size: 1.2em;
    padding-top: 0.5rem;
    width: 33rem;
  }

  .offer__box {
    display: flex;
    padding-bottom: 1rem;
    justify-content: center;
  }

  .statistics {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .testimonial__text p {
    margin: 0 8rem;
  }

  .join h3 {
    font-size: 3.5em;
  }
}

@media (max-width: 610px) {
  .arrow__left svg {
    position: relative;
    left: 1rem;
  }

  .arrow__right svg {
    position: relative;
    right: 1rem;
  }

  .testimonial__text p {
    margin: 0 6rem;
  }

  .join h3 {
    font-size: 3em;
  }
}

@media (max-width: 550px) {
  .event__page {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .offer__text p {
    font-size: 1.2em;
    padding-top: 0.5rem;
    width: 25rem;
  }

  .team__page {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }

  .testimonial__text p {
    margin: 0 2rem;
  }

  .arrow__left svg {
    position: relative;
    left: 2rem;
  }

  .arrow__right svg {
    position: relative;
    right: 2rem;
  }

  .join h3 {
    font-size: 2.5em;
  }
}

@media (max-width: 450px) {
  .offer__text p {
    font-size: 1.2em;
    padding-top: 0.5rem;
    width: 20rem;
  }

  .statistics {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event__page {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 380px) {
  .offer__text p {
    font-size: 1.2em;
    padding-top: 0.5rem;
    width: 16rem;
  }
}
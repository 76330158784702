.contact_container {
    margin: 1rem;
} 

.contact_info {
    display: flex;
    flex-direction: column;
    padding: 2rem;
   
    width: 300px;
    border: 1px solid #13a014;
    border-radius: 7px;
    background-color: #fff;
}

.contact_form {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 0.4rem;
    font-size: 16px;
    color: #13a014;
    margin: 0 auto;
    padding-left: 2rem;
    border: 1px solid #13a014;
    width: 400px;
    margin-top: 3rem;
    background-color: #fff;
    padding-bottom: 1rem;
    border-radius: 10px;
}

input {
    width: 90%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
        border: 1px solid #13a014;
    }
}

textarea {
    width: 90%;
    max-height: 150px;
    min-height: 150px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
        border: 1px solid #13a014;
    }
}

label {
    margin-top: 1rem;
}

input[type="submit"] {
    cursor: pointer;
    color: #13a014;
    border: none;
    border: 1px solid #13a014;
    width: 100%;
    align-self: center;
    margin: 1rem auto 0;

    &:hover {
        background-color: #13a014;
        border: none;
        color: #fff;
        transition: 0.3s;
    }
}

.email_address {
    text-align: center;
    padding: 2rem 0 0;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.email_address a {
    text-decoration: none;
    color: #000000;
}

.email_address a:hover {
    color: #13a014;
}

.email_address span {
    font-size: 1.4em;
    color: var(--main-color);
    padding-right: 1rem;
}

.location p {
    text-align: left;
}

@media(max-width: 900px) {
    .contact_container {
        flex-direction: column;
        margin: 0;
    }

    .contact_info {
        width: 60%;
        margin: 0;
    }

    .contact_form {
        width: 100%;
        margin: 0;
    }
    .contact_container{
        width: 60%;
        line-break: anywhere;
    }
}
@media(max-width: 500px) {
    .contact_container {
        width: 80%;
    }
    .contact_info {
        margin: 0;
        width: 80%;
    }
}